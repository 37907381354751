@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;

  @media (--viewportMedium) {
    flex-basis: 567px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.languages {
  /* Layout */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;

  margin-top: 24px;
}

.language {
  /* Font */
  text-align: left;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 9px 12px;

  /* Style */
  border: 1px solid transparent;
  border-radius: var(--borderRadiusMedium);

  &:hover {
    text-decoration: none;
    background-color: var(--colorGrey100);
  }
}

.selectedLanguage {
  border-color: var(--colorGrey700);
}

.languageContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.languageText,
.languageCountry {
  font-size: 14px;
  line-height: 20px;
}

.languageCountry {
  color: var(--colorGrey400);
}
